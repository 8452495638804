import eeuu from '../../images/eeuu.png'
import arg from '../../images/argentina.png'
import iconoStock from '../../images/stockDisponible.png'
import wapp from '../../images/wapp.png'


export default function ModalArticulo({ selectedArticulo, closeModal }) {


    const enviarWapp = (precio) => {
        const message = `FERRETERIA PICCO\nArtículo: ${selectedArticulo?.Descripcion?.trim()}.\nPrecio: $${parseFloat(precio)?.toFixed(2)}`;
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;

        window.open(whatsappUrl, "_blank");
    };


    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-80 flex justify-center items-center z-50">
            <div className="bg-white p-6 m-1 rounded-sm shadow-lg w-full lg:max-w-lg border border-black relative">
                <div className='flex justify-center items-center mb-0 -mt-5'>
                    <p className='text-center text-md font-semibold'>Precio actualizado al: {selectedArticulo?.fupcosto}</p>
                </div>
                <div className="flex justify-center items-center">
                    <img
                        src={selectedArticulo?.Monedadolar?.trim() == '1' ? eeuu : arg}
                        alt="bandera"
                        className="w-6 h-6 mr-4"
                    />
                    <h2 className="text-lg text-center font-bold mr-4">{selectedArticulo?.Codigo}</h2>
                    {selectedArticulo?.Stockactual?.trim() !== '0' && (
                        <img src={iconoStock} alt="stock" className='w-7 h-6' />
                    )}

                </div>

                <h2 className="text-lg text-center font-bold text-blue-700">
                    {selectedArticulo?.Descripcion?.trim()}
                </h2>

                <hr className="mb-1 border border-gray-300" />

                <p className='text-center text-red-600 font-semibold'>PRECIO FINAL</p>
                <div className='flex justify-between items-center'>
                    <p className='font-black'>PvtaP1</p>
                    <p className='font-black'>PvtaP2</p>
                    <p className='font-black'>PvtaP3</p>
                </div>
                <div className='flex justify-between items-center'>
                    <p>{parseFloat(selectedArticulo?.Prevtapub1)?.toFixed(2)}</p>
                    <p>{parseFloat(selectedArticulo?.Prevtapub2)?.toFixed(2)}</p>
                    <p>{parseFloat(selectedArticulo?.Prevtapub3)?.toFixed(2)}</p>
                </div>
                <div className='flex justify-between items-center'>
                    <button onClick={() => enviarWapp(selectedArticulo?.Prevtapub1)} className='text-center ml-1 '>
                        <img src={wapp} alt="icono de wapp" className='w-10 h-10 ' />
                    </button>
                    <button onClick={() => enviarWapp(selectedArticulo?.Prevtapub2)} className='text-center '>
                        <img src={wapp} alt="icono de wapp" className='w-10 h-10 ' />
                    </button>
                    <button onClick={() => enviarWapp(selectedArticulo?.Prevtapub3)} className='text-center mr-2 '>
                        <img src={wapp} alt="icono de wapp" className='w-10 h-10 ' />
                    </button>
                </div>

                <hr className='mt-2 mb-1 border border-gray-300' />

                <p className='text-red-600 font-semibold text-center'>PRECIO SIN IVA</p>
                <div className='flex justify-between items-center'>
                    <p className='font-black'>PvtaP1</p>
                    <p className='font-black'>PvtaP2</p>
                    <p className='font-black'>PvtaP3</p>
                </div>
                <div className='flex justify-between items-center mb-7'>
                    <p> {selectedArticulo?.Codtipoiva == '1' ? (parseFloat(selectedArticulo?.Prevtapub1) / 1.21)?.toFixed(2) : selectedArticulo?.Codtipoiva == '2' ? (parseFloat(selectedArticulo?.Prevtapub1) / 1.27)?.toFixed(2) : selectedArticulo?.Codtipoiva == '3' ? (parseFloat(selectedArticulo?.Prevtapub1) / 1.105)?.toFixed(2) : selectedArticulo?.Codtipoiva == '4' ? (parseFloat(selectedArticulo?.Prevtapub1) / 1)?.toFixed(2) : parseFloat(selectedArticulo?.Prevtapub1)?.toFixed(2)}</p>
                    <p> {selectedArticulo?.Codtipoiva == '1' ? (parseFloat(selectedArticulo?.Prevtapub2) / 1.21)?.toFixed(2) : selectedArticulo?.Codtipoiva == '2' ? (parseFloat(selectedArticulo?.Prevtapub2) / 1.27)?.toFixed(2) : selectedArticulo?.Codtipoiva == '3' ? (parseFloat(selectedArticulo?.Prevtapub2) / 1.105)?.toFixed(2) : selectedArticulo?.Codtipoiva == '4' ? (parseFloat(selectedArticulo?.Prevtapub2) / 1)?.toFixed(2) : parseFloat(selectedArticulo?.Prevtapub2)?.toFixed(2)}</p>
                    <p> {selectedArticulo?.Codtipoiva == '1' ? (parseFloat(selectedArticulo?.Prevtapub3) / 1.21)?.toFixed(2) : selectedArticulo?.Codtipoiva == '2' ? (parseFloat(selectedArticulo?.Prevtapub3) / 1.27)?.toFixed(2) : selectedArticulo?.Codtipoiva == '3' ? (parseFloat(selectedArticulo?.Prevtapub3) / 1.105)?.toFixed(2) : selectedArticulo?.Codtipoiva == '4' ? (parseFloat(selectedArticulo?.Prevtapub3) / 1)?.toFixed(2) : parseFloat(selectedArticulo?.Prevtapub3)?.toFixed(2)}</p>
                </div>

                {/* <div className="flex justify-between items-center mt-3 -m-2 mb-10 ">
                    <div className="self-start text-left">
                        <p className='font-semibold text-md text-red-600'>PRECIO FINAL</p>
                        <div className='flex items-center mb-3'>
                            <p className="text-md ">
                                <strong className="font-black">PvtaP1 :</strong> {parseFloat(selectedArticulo?.Prevtapub1)?.toFixed(2)}
                            </p>
                            <button onClick={() => enviarWapp(selectedArticulo?.Prevtapub1)}>
                                <img src={wapp} alt="icono de wapp" className='w-10 h-10 ml-4' />
                            </button>
                        </div>
                        <div className='flex items-center mb-3'>
                            <p className="text-md ">
                                <strong className="font-black">PvtaP2 :</strong> {parseFloat(selectedArticulo?.Prevtapub2)?.toFixed(2)}
                            </p>
                            <button onClick={() => enviarWapp(selectedArticulo?.Prevtapub2)}>
                                <img src={wapp} alt="icono de wapp" className='w-10 h-10 ml-4' />
                            </button>
                        </div>
                        <div className='flex items-center'>
                            <p className="text-md ">
                                <strong className="font-black">PvtaP3 :</strong> {parseFloat(selectedArticulo?.Prevtapub3)?.toFixed(2)}
                            </p>
                            <button onClick={() => enviarWapp(selectedArticulo?.Prevtapub3)}>
                                <img src={wapp} alt="icono de wapp" className='w-10 h-10 ml-4' />
                            </button>
                        </div>
                    </div>
                    <div className="self-start text-left">
                        <p className='font-semibold text-md text-red-600'>PRECIO SIN IVA</p>
                        <p className="text-md">
                            <strong className="font-black">PvtaP1 :</strong> {selectedArticulo?.Codtipoiva == '1' ? (parseFloat(selectedArticulo?.Prevtapub1) / 1.21)?.toFixed(2) : selectedArticulo?.Codtipoiva == '2' ? (parseFloat(selectedArticulo?.Prevtapub1) / 1.27)?.toFixed(2) : selectedArticulo?.Codtipoiva == '3' ? (parseFloat(selectedArticulo?.Prevtapub1) / 1.105)?.toFixed(2) : selectedArticulo?.Codtipoiva == '4' ? (parseFloat(selectedArticulo?.Prevtapub1) / 1)?.toFixed(2) : parseFloat(selectedArticulo?.Prevtapub1)?.toFixed(2)}
                        </p>
                        <p className="text-md">
                            <strong className="font-black">PvtaP2 :</strong> {selectedArticulo?.Codtipoiva == '1' ? (parseFloat(selectedArticulo?.Prevtapub2) / 1.21)?.toFixed(2) : selectedArticulo?.Codtipoiva == '2' ? (parseFloat(selectedArticulo?.Prevtapub2) / 1.27)?.toFixed(2) : selectedArticulo?.Codtipoiva == '3' ? (parseFloat(selectedArticulo?.Prevtapub2) / 1.105)?.toFixed(2) : selectedArticulo?.Codtipoiva == '4' ? (parseFloat(selectedArticulo?.Prevtapub2) / 1)?.toFixed(2) : parseFloat(selectedArticulo?.Prevtapub2)?.toFixed(2)}
                        </p>
                        <p className="text-md">
                            <strong className="font-black">PvtaP3 :</strong> {selectedArticulo?.Codtipoiva == '1' ? (parseFloat(selectedArticulo?.Prevtapub3) / 1.21)?.toFixed(2) : selectedArticulo?.Codtipoiva == '2' ? (parseFloat(selectedArticulo?.Prevtapub3) / 1.27)?.toFixed(2) : selectedArticulo?.Codtipoiva == '3' ? (parseFloat(selectedArticulo?.Prevtapub3) / 1.105)?.toFixed(2) : selectedArticulo?.Codtipoiva == '4' ? (parseFloat(selectedArticulo?.Prevtapub3) / 1)?.toFixed(2) : parseFloat(selectedArticulo?.Prevtapub3)?.toFixed(2)}
                        </p>
                    </div>
                </div> */}

                <div className="absolute bottom-0 left-0 w-full">
                    <button
                        className="px-4 py-2 bg-red-500 text-white font-semibold w-full text-xl"
                        onClick={closeModal}
                    >
                        CERRAR
                    </button>
                </div>
            </div>
        </div>

    )
}
