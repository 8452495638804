import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import icono from '../../images/usuario.png'


export default function Login() {
    const navigate = useNavigate();

    const [nombre, setNombre] = useState('')
    const [clave, setClave] = useState('')
    const [errorMensaje, setErrorMensaje] = useState('')
    const [showPassword, setShowPassword] = useState(false);


    const handleNombreChange = (e) => {
        const nombreIngresado = e.target.value.trim()
        setNombre(nombreIngresado)
        setErrorMensaje('')
    }

    const handleClaveChange = (e) => {
        const claveIngresado = e.target.value.trim()
        setClave(claveIngresado)
        setErrorMensaje('')
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleLogin = async (e) => {

        if (!nombre || nombre.trim() === "") {
            setErrorMensaje('¡Ingrese Usuario!');
            return;
        }

        if (!clave || clave.trim() === "") {
            setErrorMensaje('¡Ingrese Contraseña!');
            return;
        }


        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, { nombre, clave })

            if (response.status === 200) {
                const { token } = response.data
                localStorage.setItem('auth_usuario', token);
                navigate('/layout');
            }

        } catch (error) {
            if (error.response && error.response.status === 401) {
                setErrorMensaje(error.response.data.message);
            } else {
                setErrorMensaje('Error al iniciar sesión.');
            }
        }
    }

    return (

        <body className="bg-white">
            <div className="flex justify-center h-screen w-screen items-center">
                <div className="w-full md:w-1/2 flex flex-col items-center " >

                    <img src={icono} alt="usuario" className='w-20 h-20 mb-8' />

                    <div className="w-72 lg:w-96 mb-6">
                        <input
                            type="text"
                            autoComplete='off'
                            name="usuario"
                            id="usuario"
                            className="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500"
                            placeholder="Usuario..."
                            onChange={handleNombreChange}
                        />
                    </div>

                    <div className="w-72 lg:w-96 mb-6 relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            id="password"
                            className="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500 "
                            placeholder="Contraseña..."
                            onChange={handleClaveChange}
                        />

                        <button
                            type="button"
                            onClick={toggleShowPassword}
                            className="absolute right-4 top-4 "
                        >
                            {showPassword ? '👁' : '👁‍🗨'}
                        </button>

                    </div>

                    {errorMensaje && <p className="text-red-500 font-semibold text-center mb-2 -mt-2 -lg:mt-4">{errorMensaje}</p>}

                    <div className="w-72 lg:w-96 mt-4">
                        <button
                            type="submit"
                            className="py-4 bg-blue-500 w-full rounded text-blue-50 font-bold hover:bg-blue-700"
                            onClick={handleLogin}
                        >
                            INGRESAR
                        </button>
                    </div>
                </div>
            </div>
        </body>
    )
}
